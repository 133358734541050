@import '~app/mixins';

.tile {
  margin: 12px 7px 10px 0;
  width: 90px;
  min-width: 90px;
  max-width: 90px;
  border: 1px solid $color-border;
  border-radius: 6px;
  background-color: $color-white;
  padding: 8px 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $color-grey;
  position: relative;
  cursor: pointer;

  .label {
    font-size: 14px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;

    svg {
      fill: #aaa;
      width: 10px;
      height: 10px;
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: blue;
      }
    }
  }

  .value {
    font-size: 20px;
    font-weight: bold;
    margin-top: -2px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 20px;
      display: none;
    }

    &.up {
      color: #218c0a;

      svg {
        display: inline-block;
        fill: #218c0a;
        transform: rotate(-90deg);
        margin-bottom: 5px;
        margin-right: 6px;
      }
    }

    &.down {
      color: #b40000;

      svg {
        display: inline-block;
        fill: #b40000;
        transform: rotate(90deg);
        margin-top: 6px;
      }
    }
  }

  &.custom .edit svg {
    fill: blue;
  }

  &.grayed,
  &.disabled {
    color: $color-grey-light;

    .label {
      font-style: italic;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.disabled .edit {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: $color-blue-light;
  }

  &.active {
    background-color: $color-light-blue;
  }

  /*
  &:not(.disabled) {
    &:hover,
    &:focus {
      cursor: pointer;
      border-color: $color-blue-light;
    }

    &.active {
      background-color: $color-light-blue;
    }
  }
  */
}
